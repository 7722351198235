<template>
  <v-card>
    <v-card-title>
      Localizar Grupos Reserva
    </v-card-title>
    <!-- filter quicksearch -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="dialogFilter = true"
        >
          <v-icon>{{ icons.mdiFilter }}</v-icon>
          <span>Filtrar</span>
        </v-btn>

        <v-btn
          color="secondary"
          outlined
          class="mb-4"
          @click="filterReset"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiFilterRemove }}
          </v-icon>
          <span>Limpar</span>
        </v-btn>
      </div>
    </v-card-text>

    <!--table-->
    <v-data-table
      :headers="headers"
      :items="groupReserveList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      fixed-header
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      @page-count="pageCount = $event"
    >
      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>
    </v-data-table>

    <v-divider class="mt-4"></v-divider>

    <!-- pagination -->
    <v-card-text class="pt-2 pb-1">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-center"
        >
          <v-pagination
            v-model="pageOptions.page"
            total-visible="6"
            :length="pageOptions.pageCount"
            @input="updatePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="dialogFilter"
      persistent
      width="900"
    >
      <v-card>
        <v-card>
          <v-card-text>
            <v-card
              outlined
              class="pb-5 pt-5 pr-5 pl-5"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-autocomplete
                    v-model="filterOptions.sales_plan"
                    dense
                    :items="salesPlanList"
                    outlined
                    chips
                    multiple
                    hide-details
                    small-chips
                    label="Planos de Venda"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  md="6"
                >
                  <v-autocomplete
                    v-model="filterOptions.due_day"
                    dense
                    :items="dueDayList"
                    outlined
                    chips
                    hide-details
                    small-chips
                    multiple
                    label="Vencimento"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="pb-1"
                >
                  <v-text-field
                    v-model="filterOptions.parcel_number"
                    dense
                    type="number"
                    label="Prazo"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="pb-1"
                >
                  <v-text-field
                    v-model="filterOptions.bid_initial"
                    label="Lance Inicial"
                    type="number"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-1"
                >
                  <v-text-field
                    v-model="filterOptions.bid_final"
                    label="Lance Final"
                    type="number"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  class="pb-1"
                >
                  <v-text-field
                    v-model="filterOptions.reservation_lowest"
                    label="Menor Reserva"
                    type="number"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-3"
                >
                  <v-text-field
                    v-model="filterOptions.reservation_highest"
                    label="Maior Reserva"
                    type="number"
                    outlined
                    hide-details
                    dense
                    class="mb-4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="error"
              @click="dialogFilter = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              outlined
              color="success"
              @click="filterReserveGroup"
            >
              Filtrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@axios'
import {
  mdiAccountOutline,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    dialogFilter: false,
    dialog: false,
    dialogDelete: false,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
      mdiCalendar,
      mdiFilterMenu,
      mdiLoading,
      mdiEyeOutline,
    },

    /* filter */
    filterOptions: {
      sales_plan: [],
      due_day: [],
      bid_initial: '',
      bid_final: '',
      reservation_lowest: '',
      reservation_highest: '',
      parcel_number: '',
    },
    dateMenu: false,
    dueDayList: ['20', '18', '15', '11', '1'],
    salesPlanList: [
      'Especial',
      'Super Legal',
      'Master',
      'Conquista',
      'TRX E CRF',
      'Mega Fácil',
      'Minha Scooter Honda',
      'Multichances',
      'Vou de Honda',
      'Vou de Honda +',
    ],

    /* data table */
    groupReserveList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: true,
    headers: [
      { text: 'PLANO', value: 'sales_plan' },
      { text: 'GRUPO', value: 'group_number' },
      { text: 'RESERVA', value: 'reservation' },
      { text: 'PRAZO', value: 'parcel_number' },
      { text: 'MÍNIMO', value: 'bid_lowest' },
      { text: 'MÉDIA', value: 'average' },
      { text: 'MÁXIMA', value: 'bid_highest' },
      { text: 'VENCT', value: 'due_day' },
      { text: 'PROX ASSEMBLEIA', value: 'assembly_date_next' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },
  }),

  watch: {
    //
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await axiosIns
        .get('/api/v1/integration/reserve_group/index')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.groupReserveList.push({
              id: value.id,
              group_number: value.group_number,
              sales_plan: value.sales_plan,
              reservation: value.reservation,
              assembly_date_next: value.assembly_date_next,
              bid_highest: value.bid_highest,
              bid_lowest: value.bid_lowest,
              due_day: value.due_day,
              assembly_number: value.assembly_number,
              parcel_number: value.parcel_number,
              average: value.average,
            })
          })
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async updatePage() {
      this.loading = true
      this.groupReserveList = []
      if (this.routeOptions.method === 'get') {
        await axiosIns
          .get(`/api/v1/integration/reserve_group/${this.routeOptions.resource}?page=${this.pageOptions.page}`)
          .then(res => {
            res.data.data.map(value => {
              this.groupReserveList.push({
                id: value.id,
                group_number: value.group_number,
                sales_plan: value.sales_plan,
                reservation: value.reservation,
                assembly_date_next: value.assembly_date_next,
                bid_highest: value.bid_highest,
                bid_lowest: value.bid_lowest,
                due_day: value.due_day,
                assembly_number: value.assembly_number,
                parcel_number: value.parcel_number,
                average: value.average,
              })
            })
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.noDataText = `Contate o suporte do sistema: ${err}`
          })
      } else if (this.routeOptions.method === 'post') {
        await axiosIns
          .post(
            `/api/v1/integration/reserve_group/${this.routeOptions.resource}?page=${this.pageOptions.page}`,
            this.routeOptions.params,
          )
          .then(res => {
            res.data.data.map(value => {
              this.groupReserveList.push({
                id: value.id,
                group_number: value.group_number,
                sales_plan: value.sales_plan,
                reservation: value.reservation,
                assembly_date_next: value.assembly_date_next,
                bid_highest: value.bid_highest,
                bid_lowest: value.bid_lowest,
                due_day: value.due_day,
                assembly_number: value.assembly_number,
                parcel_number: value.parcel_number,
                average: value.average,
              })
            })
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.noDataText = `Contate o suporte do sistema: ${err}`
          })
      }
    },

    async filterReserveGroup() {
      this.loading = true
      this.routeOptions = {
        resource: 'reservation_group_location',
        method: 'post',
        params: this.filterOptions,
      }
      this.groupReserveList = []
      this.dialogFilter = false

      await axiosIns
        .post('/api/v1/integration/reserve_group/reservation_group_location', this.routeOptions.params)
        .then(res => {
          res.data.data.map(value => {
            this.groupReserveList.push({
              id: value.id,
              group_number: value.group_number,
              sales_plan: value.sales_plan,
              reservation: value.reservation,
              assembly_date_next: value.assembly_date_next,
              bid_highest: value.bid_highest,
              bid_lowest: value.bid_lowest,
              due_day: value.due_day,
              assembly_number: value.assembly_number,
              parcel_number: value.parcel_number,
              average: value.average,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async filterReset() {
      this.loading = true
      this.groupReserveList = []
      Object.keys(this.filterOptions).forEach(key => {
        this.filterOptions[key] = ''
      })
      this.routeOptions = {
        resource: 'index',
        method: 'get',
        params: {},
      }

      this.initialize()
    },

    chatWhatsapp(phone) {
      window.open(`https://wa.me/55${phone}`, '_blank')
    },
  },
}
</script>
